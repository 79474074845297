import React from "react";
import { ethers } from "ethers";
// import { ConnectWallet } from "../ConnectWallet";
import { NetworkErrorMessage } from "../NetworkErrorMessage";

export function Nav(props) {
  const tokenBalance = ethers.utils.formatUnits(props.tokenBalance, props.token.decimals);
  const ethBalance = ethers.utils.formatEther(props.ethBalance);
  // const hogePrice = String(props.ethDisplay / props.hogePerEth).substring(0,9);
  
    return (
      <div className="nav">
        <a href="http://otcswap.pro/">
          <img className="logo-otc-swap-pro-ko" src="Logo-OTCSwap-Pro.svg" />
        </a>
        <div className="wallet-info-1 roboto-medium-white-12px">
          {!props.initialized ||
          <div style={{display: 'flex', justifyContent: 'flex-end', gap: '16px', fontWeight: '400'}}>
            {props.permPage && 
            <div style={{display: 'flex', gap:'8px'}}>
              <div>PP</div>
              <div className="roboto-medium-white-12px">{"0.00"}
              </div>
            </div>}
            <div style={{display: 'flex', gap:'8px'}}>
              <div>{props.token.symbol}</div>
              <div className="roboto-medium-white-12px">{parseFloat(tokenBalance).toFixed(2)}
              </div>
            </div>
            <div style={{display:'flex', gap:'8px'}}>
              <div>ETH</div> 
              <div className="roboto-medium-white-12px">{parseFloat(ethBalance).toFixed(2)}
              </div>
            </div>
          </div>
          }
        </div>
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '12px'}}>
          <div className="button-6">
            <button className="connect-wallet valign-text-middle"
                type="button"
                onClick={props.initialized ? () => props._disconnectWallet() :
                                              () => props._connectWallet()}>
                {props.initialized ? "Disconnect wallet" : "Connect wallet"}
            </button>
          </div>
          {!props.initialized ||
          <div className="wallet-connected roboto-normal-white-12px">
            <div>Wallet connected: </div>
            <a style={{display: 'inline-block', width: '77px', overflow: 'hidden', textOverflow: 'ellipsis', textDecorationLine: 'underline'}}
               className="underline"
               target="_blank"
               href={`https://etherscan.io/address/` + props.selectedAddress}>{props.selectedAddress.substring(0,5) + "..." + props.selectedAddress.substring(props.selectedAddress.length - 5, props.selectedAddress.length)  }</a>
          </div>
          }
        </div>
        <div>
          {/* Metamask network should be set to Localhost:8545. */}
          {props.networkError && (
            <NetworkErrorMessage
              message={props.networkError}
              dismiss={() => props._dismissNetworkError()}
            />
          )}
        </div>
      </div>
    );
}
