import React from "react";
import "./ColLabels.css";

function ColLabels(props) {
  const { className } = props;

  return (
    <div className={`col-labels-1 ${className || ""}`}>
      <img className="line-4-1" src="line-1-1.svg" />
      <div className="labels-2 roboto-bold-mine-shaft-12px">
        <div className="limit-2 valign-text-middle">Limit</div>
        <div className="amount-1 valign-text-middle">Amount</div>
        <div className="surname-4 valign-text-middle">Price per token</div>
      </div>
      <img className="line-3-1" src="line-1-1.svg" />
    </div>
  );
}

export default ColLabels;
