import React, { useRef } from "react";

export function RowTradeD(props) {
  const { wants, quantity, pricePerToken, onClick } = props;
  const rowRef = useRef(null);

  return (
    <React.Fragment>
    <div className={`row-trade-d-1`} 
         style={{width:"100%", display:"inline-grid"}}
         ref={rowRef}>
      <div className="token-lockup">
        <img className="logo-eth" src={wants.imgName} />
        <div className="place-2 valign-text-middle roboto-bold-mine-shaft-14px">
          {wants.tokenName}
        </div>
      </div>
      <div className="token-lockup-1">
        {quantity.imgName && <img className="logo-hoge" src={quantity.imgName} />}
        <div className="x8800000 valign-text-middle roboto-normal-mine-shaft-16px">
          {quantity.quantity}
        </div>
      </div>
      <div className="pricePerToken valign-text-middle roboto-normal-mine-shaft-16px">
        ${pricePerToken}
      </div>
      <button className="button-sm"
              onClick={() => onClick(rowRef)}>
        <div className="place-3 valign-text-middle roboto-bold-white-12px">
          Trade
        </div>
      </button>
    </div>
    <img className="line-6" src="line-1-1.svg" />
    </React.Fragment>
  );
}