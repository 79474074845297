import React from "react";
import { DetailCol1 } from "./stubs/DetailCol1";
import { TokenLockup3 } from "./stubs/TokenLockup3";
import { ethers } from "ethers";
import LimitOrderBtn from "./stubs/LimitOrderBtn";

export class ManagePump extends React.Component {
  constructor(props) {
    super(props);
    const Zero = ethers.BigNumber.from("0");
    this.initialState = {
      tokenSize: "0",
      ethSize: "0",
      price: Zero,
      vendorTokenSize: Zero, 
      vendorEthSize: Zero,
      vendorPriceUsd: Zero,
      orderType: 'HOGE'
    };
    this.state = this.initialState;
  }

  getOrderData(order) {
    const {address, owner, bid, bidUsd, ask, askUsd, eth, bidMax, askMax, max_amountETH} = order;
    const buy = (this.props.tradeType == "buy");
    const sizeETH = buy ? askMax.amountETH : bidMax.amountETH;
    const sizeToken = buy ? askMax.amountToken : bidMax.amountToken;
    const price = buy ? ask : bid;
    const priceUsd = buy ? askUsd : bidUsd;

    this.setState({vendorTokenSize: sizeToken, 
                   vendorEthSize: sizeETH, 
                   vendorPriceUsd: priceUsd,
                   price: price}, this.maxEth)

  }

  maxToken() {
    let newAmount = this.state.vendorTokenSize;
    if (this.props.tradeType == "buy" && this.props.ethBalance.lte(this.state.vendorEthSize)) {
      this.maxEth();
      return;
    }
    if (this.props.tradeType == "sell" && this.props.tokenBalance.lte(this.state.vendorTokenSize)) {
      newAmount = this.props.tokenBalance;
    }
    this.setState({tokenSize: ethers.utils.formatUnits(newAmount, this.props.token.decimals)}, 
      this.updateEth);
  }

  maxEth() {
    let newAmount = this.state.vendorEthSize;
    if (this.props.tradeType == "buy" && this.props.ethBalance.lte(this.state.vendorEthSize)) {
      const gasMargin = ethers.utils.parseEther(".01");
      if (this.props.ethBalance.gte(gasMargin)) {
        newAmount = this.props.ethBalance.sub(gasMargin);
      } else {
        newAmount = ethers.BigNumber.from("0");
      }
    }
    if (this.props.tradeType == "sell" && this.props.tokenBalance.gte(this.state.vendorTokenSize)) {
      this.maxToken();
      return;
    }
    this.setState({ethSize: ethers.utils.formatEther(newAmount, this.props.token.decimals)}, 
      this.updateToken);
  }

  updateToken() {
    if (this.state.price.isZero()) return;
    const eth = ethers.utils.parseEther(this.state.ethSize || "0");
    const newTokenSize = eth.div(this.state.price).mul(10**9);
    this.setState({tokenSize: ethers.utils.formatUnits(newTokenSize, this.props.token.decimals)});
  }

  updateEth() {
    const tokens = ethers.utils.parseUnits(this.state.tokenSize || "0", this.props.token.decimals);
    const newEthSize = tokens.mul(this.state.price).div(10**9);
    this.setState({ethSize: ethers.utils.formatEther(newEthSize)});
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.orderSelected?.address !== this.props.orderSelected?.address) {
      if (this.props.orderSelected) {
        this.getOrderData(this.props.orderSelected);
      } else {
        this.setState(this.initialState);
      }
    }
  }

  render() {
    const {tradeType, token, orderSelected, initialized} = this.props;

    const sizeEth = ethers.utils.formatEther(this.state.vendorEthSize).substring(0,10);
    const sizeToken = ethers.utils.commify(Math.trunc(ethers.utils.formatUnits(this.state.vendorTokenSize, this.props.token.decimals)));
    const price = ethers.utils.formatUnits(this.state.vendorPriceUsd, 18).substring(0,11);
    const buy = (this.props.tradeType == "buy");

    return (
      <div className="confirm-trade" style={{borderRadius: "20px 20px 20px 20px"}}>
        <div className="confirm-trade-1 roboto-bold-white-32px" style={{fontSize: "26px"}}>
          Permanent Pump Tokens
        </div>
        <div className="body">
          <div className="trade-detail border-1px-white">
            <DetailCol1
              title={"Reserves:"}
              valueOld={orderSelected && (buy ? sizeEth : sizeToken)}
              value="2.9999"
              unit={buy ? "ETH" : token.symbol}
            />
            <DetailCol1
              title={""}
              valueOld={orderSelected && (price)}
              value="20,000,000"
              unit={token.symbol}
            />
          </div>
          <p className="for-a-partial-fill roboto-normal-white-14px">
            <span className="roboto-normal-white-14px">Crowd-owned liquidity wall. Add ETH or {token.symbol} to mint new PP.</span>
          </p>
            <div className="tabs-1" style={{width:"306px", marginTop: "unset"}}>
              <LimitOrderBtn status={this.state.orderType === 'HOGE'} 
                            style={{borderRadius:"6px 0px 0px 6px", minWidth: "33.3%", padding: "17px 37.5px"}}
                            onClick={() => this.setState({orderType: 'HOGE'})}
                            disabled={!initialized}>
                              HOGE
              </LimitOrderBtn>
              <LimitOrderBtn status={this.state.orderType === 'ETH'}
                            style={{borderRadius:"0px 6px 6px 0px", minWidth: "33.3%", padding: "17px 37.5px"}}
                            onClick={() => this.setState({orderType: 'ETH'})}
                            disabled={!initialized}>
                              ETH
              </LimitOrderBtn>
              <LimitOrderBtn status={this.state.orderType === 'PP'}
                            style={{borderRadius:"0px 6px 6px 0px", minWidth: "33.3%", padding: "17px 37.5px"}}
                            onClick={() => this.setState({orderType: 'PP'})}
                            disabled={!initialized}>
                              PP
              </LimitOrderBtn>
            </div>
          <div className="fields-body" style={{minHeight:"unset"}}>
            {this.state.orderType === 'HOGE' && <div className="field-labeled-disabled">
              <div className="you-buy valign-text-middle roboto-bold-white-12px">
                Add {token.symbol}
              </div>
              <div className="field">
                <input 
                  className="text valign-text-middle roboto-bold-white-16px" 
                  style={{background:"transparent", opacity: orderSelected ? "1" : "0.5"}} type="number" step="any" name="tokenSize" required
                  value={this.state.tokenSize}
                  disabled={!initialized}
                  onChange={
                    (event) => {
                        let val = event.target.value;
                        this.setState({tokenSize: val}, this.updateEth);
                      }
                  }
                />
                <div className="token-info">
                  <button className="name valign-text-middle roboto-medium-white-10px"
                          disabled={!initialized}
                          onClick={() => {
                            this.maxToken();
                          }}>
                    MAX
                  </button>
                  <TokenLockup3
                    logoHoge={token.logo}
                    hoge={token.symbol}
                  />
                </div>
              </div>
            </div>}
            {this.state.orderType === 'ETH' && <div className="field-labeled-disabled">
              <div className="you-spend valign-text-middle roboto-bold-white-12px">
                Add ETH
              </div>
              <div className="field-1">
                <input 
                  className="text valign-text-middle roboto-bold-white-16px"
                  style={{background:"transparent", opacity: orderSelected ? "1" : "0.5"}} type="number" step="any" name="price" required
                  value={this.state.ethSize}
                  disabled={!initialized}
                  onChange={
                    (event) => {
                        let val = event.target.value;
                        // if (val === "" || val === "0") { val = "0.0"};
                        this.setState({ethSize: val}, this.updateToken);

                      }
                  }
                />
                <div className="token-info-1">
                  <button className="name valign-text-middle roboto-medium-white-10px"
                       disabled={!initialized}
                       onClick={() => this.maxEth()}>
                    MAX
                  </button>
                  <TokenLockup3
                    logoHoge={"logo-eth.png"}
                    hoge={"ETH"}
                  />
                </div>
              </div>
            </div>}
            {this.state.orderType === 'PP' && <div className="field-labeled-disabled">
              <div className="you-buy valign-text-middle roboto-bold-white-12px">
                Remove PP
              </div>
              <div className="field">
                <input 
                  className="text valign-text-middle roboto-bold-white-16px" 
                  style={{background:"transparent", opacity: orderSelected ? "1" : "0.5"}} type="number" step="any" name="tokenSize" required
                  value={this.state.tokenSize}
                  disabled={!initialized}
                  onChange={
                    (event) => {
                        let val = event.target.value;
                        this.setState({tokenSize: val}, this.updateEth);
                      }
                  }
                />
                <div className="token-info">
                  <button className="name valign-text-middle roboto-medium-white-10px"
                          disabled={!initialized}
                          onClick={() => {
                            this.maxToken();
                          }}>
                    MAX
                  </button>
                  <TokenLockup3
                    logoHoge={token.logo}
                    hoge={"PP"}
                  />
                </div>
              </div>
            </div>}
            <div className="cta-lockup">
              <div className="button-2">
                <button className="accept-trade valign-text-middle roboto-bold-chateau-green-14px"
                        disabled={!initialized}
                        onClick={() => {
                          if (orderSelected) {
                            if (this.props.tradeType === 'buy') {
                              orderSelected.callbacks?.buy(this.state.ethSize);
                            } else {
                              orderSelected.callbacks?.sell(this.state.tokenSize);
                            }
                          }
                        }}>
                  Accept trade
                </button>
              </div>
              <p className="or-select-a-differen roboto-medium-white-10px">
                <span className="roboto-medium-white-10px">SOMETHING SPECIFIC TO PERMANENT PUMP</span>
              </p>
            </div>
          </div>
        </div>
    </div>
    );
  }
}
