import { ethers } from "ethers";
import React, { useState, useEffect } from 'react';
import useBreakpoint from 'use-breakpoint';

const BREAKPOINTS = { mobile: 0, desktop: 768 };

export function _tokenDisplay (amount) {
  if (amount.isZero()) return "0";
  let tokenBalance = ethers.utils.formatUnits(amount, 9)//this.state.token.decimals)
  let realDecimals = tokenBalance.split(".")[1].length;
  tokenBalance = tokenBalance.substring(0, tokenBalance.length - realDecimals + 4);
  return ethers.utils.commify(tokenBalance);
}

export function CurrentBreakpoint (props) {
  const { rowCount, setCount } = {...props}
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'desktop');
  const newRowCount = (breakpoint === 'desktop' ? 4 : 3);
  useEffect(() => {
    if (rowCount !== newRowCount) {
      setCount(newRowCount);
    }
  });

  return <></>;
};