import React from "react";
import { Route, Router, Routes } from "react-router-dom";
import {OtcSwap} from "./OtcSwap";
import {PermanentPump} from "./PermanentPump";

export class Dapp extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return (
      <div>
        <Routes>
          <Route path="/pump" element={<PermanentPump/>}/>
          <Route path="/" element={<OtcSwap/>}/>
        </Routes>
      </div>
    );
  }
}
