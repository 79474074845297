import React from "react";
import "./LimitOrderBtn.css";

function LimitOrderBtn(props) {
  const { children, status, style, onClick, disabled} = props;
  const activeCls = "activeBtn roboto-bold-chateau-green-14px";
  const inactiveCls = "inactiveBtn border-1px-white roboto-bold-white-14px";

  return (
    <button style={style} className={"limitOrderBtn " + (status ? activeCls : inactiveCls)} onClick={() => onClick()} disabled={disabled}>
      <div className="limitOrderBtnTxt valign-text-middle">{children}</div>
    </button>
  );
}

export default LimitOrderBtn;
