import React from "react";

export function DetailCol1(props) {
  const { title, value, unit } = props;
  const prefix = props.prefix ? props.prefix : "";

  return (
    <div className="detail-col">
      <div className="someone-wants-1 valign-text-middle roboto-bold-white-12px">
        {title}
      </div>
      <div className="text-2 valign-text-middle roboto-bold-mine-shaft-16px">
        {value ? prefix + value : "–"}
      </div>
      <div className="place-4 valign-text-middle roboto-bold-white-12px">
        {unit}
      </div>
    </div>
  );
}