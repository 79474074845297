import React from "react";
import { BigNumber, ethers } from "ethers";
import pairArtifact from "../contracts/pair.json";
import TokenVendorArtifact from "../contracts/TokenVendor.json";
import VendorFactoryArtifact from "../contracts/VendorFactory.json";
import erc20ABI from "../contracts/erc20.json";
import { ManagePump } from "./ManagePump";
import 'bootstrap/dist/css/bootstrap.css';

import { Nav } from './stubs/Nav';
import "../OrderPanel.css";

import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";

const supportedTokens = require("../contracts/supported-tokens.json");
const factoryAddress = require("../contracts/factory-address.json");

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
const zero = ethers.BigNumber.from("0");

export class PermanentPump extends React.Component {
  constructor(props) {
    super(props);
    
    this.initialState = {

      tokenData: undefined,
      selectedAddress: undefined,
      initialized: false,
      tradeType:'buy',
      bids:{},
      asks:{},
      orderSelected:false, // bid or ask in state
      tokenBalance: zero,
      ethBalance: zero,
      usdPerWei: ethers.BigNumber.from("1"),
      txBeingSent: undefined,
      txApprovalNext: false,
      transactionError: undefined,
      networkError: undefined,
      token: supportedTokens['hoge'],
      tokenReserves: zero, 
      ethReserves: zero,
      tradeTab: 0,
      orderTab: 0,
      hiddenMobileTab: 'Limit',
      rowCount: 4
  };
    this.state = this.initialState;
  }

  render() {
    return (
    <div className="container-center-horizontal">
      <div className="otcswap-pro-d-default screen">
        <Nav
          permPage={true}
          initialized={this.state.initialized}
          selectedAddress={this.state.selectedAddress}
          networkError={this.state.networkError}
          token={this.state.token}
          tokenBalance={this.state.tokenBalance}
          ethBalance={this.state.ethBalance}
          _connectWallet={()=>this._connectWallet()}
          _dismissNetworkError={()=>this._dismissNetworkError()}
          _disconnectWallet={()=> {
                                    this._stopPollingData();
                                    this._resetState();
                                  }}
        />
        <div style={{marginTop: "52px"}}>
            <ManagePump 
              token={this.state.token}
              tokenBalance={this.state.tokenBalance}
              ethBalance={this.state.ethBalance}
              tradeType={this.state.tradeType}
              orderSelected={this.state.orderSelected}
              initialized={this.state.initialized}
            />
        </div>
      </div>
    </div>
    );
  }

  componentWillUnmount() {
    this._stopPollingData();
  }
  
  async _connectWallet() {
    this._initializeEthers();
  }
  
  async _initializeEthers() {
    const web3Modal = new Web3Modal({
      network: "mainnet", // optional
      cacheProvider: true, // optional
      providerOptions: {  
        walletconnect: {
          package: WalletConnectProvider, // required
          options: {
            infuraId: "2582c3515a11421da3330b72e65f7bf4" // required
          }
      }} // required
    });
    const instance = await web3Modal.connect();
    this._provider = new ethers.providers.Web3Provider(instance);
  
    const signer = this._provider.getSigner();
    const address = await signer.getAddress();
    this.setState({
      selectedAddress: address,
      initialized: true
    });
  
    this._factory = new ethers.Contract(
      factoryAddress.address,
      VendorFactoryArtifact.abi,
      signer
    );
  
    instance.on("accountsChanged", ([newAddress]) => {
      this._stopPollingData();
      if (newAddress === undefined) {
        return this._resetState();
      }
      this.setState({
        selectedAddress: newAddress,
        tokenBalance: zero,
        ethBalance: zero,
  
      });
      const new_signer = this._provider.getSigner();
      this._factory = new ethers.Contract(
        factoryAddress.address,
        VendorFactoryArtifact.abi,
        new_signer
      );
      this._loadContracts(new_signer);
      this._startPollingData();
    });
  
    this._loadContracts(signer);
    this._updateToken();
    this._startPollingData();
  }
  
  async _loadContracts(signer) { 
    this._factory.provider.resetEventsBlock(7027336); //(14450466);12160486
    this._factory.on("VendorCreated", (token, vendor) => {
      if (token.toUpperCase() !== this.state.token.address.toUpperCase()) return;
      this._addVendor(vendor, signer);
    });
  }
  // call when initializing or switching token
  async _updateToken(newToken = false) {
    if(newToken) {
      this.setState({token: supportedTokens[newToken]}, () => {
        this._updateToken();
      });
    } else {
      this._token = new ethers.Contract(
        this.state.token.address,
        erc20ABI,
        this._provider.getSigner(0)
      );
      let symbol = await this._token.symbol();
      let decimals = await this._token.decimals();
  
      this._pair = new ethers.Contract(
        this.state.token.pair,
        pairArtifact,
        this._provider.getSigner(0)
      );
    
      const token0 = await this._pair.token0();
      const [token0reserves, token1reserves] = await this._pair.getReserves();
      const [tokenReserves, ethReserves] = token0 == "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" ? [token1reserves, token0reserves] : [token0reserves, token1reserves];
      this.setState({token: {...this.state.token, symbol, decimals}, tokenReserves:tokenReserves, ethReserves:ethReserves});
    }
  }
  
  async _addVendor(address, signer) {
    let contract = new ethers.Contract(
                    address,
                    TokenVendorArtifact.abi,
                    signer
                  );
    const owner = await contract.owner();
    const empty_max = {amountETH: zero, amountToken:zero}
    const new_bid = {contract, address, owner, bid:zero, bidUsd:zero, bidMax:empty_max, askMax:empty_max, eth:this.state.usdPerWei, 
                      callbacks: {sell: (tokenAmount) => {this._sellTOKEN(contract, tokenAmount)}}};
    this.state.bids[address] = new_bid;
    const new_ask = {contract, address, owner, ask:zero, askUsd:zero, bidMax:empty_max, askMax:empty_max, eth:this.state.usdPerWei,
                      callbacks: {buy: (ethAmount) => {this._buyTOKEN(contract, ethAmount)}}};
    this.state.asks[address] = new_ask;
    this._updateVendor(address);
  }
  
  async _updateVendor(address) {
    const contract = this.state.bids[address].contract;
    const bidMax = await contract.bidSize();
    const askMax = await contract.askSize();
    if (bidMax[0].div(10**this.state.token.decimals).gt(0)) {
      const bid = await contract.getBid();
      const bidUsd = this.weiToUSD(bid).mul(10**this.state.token.decimals).div(10**9);
      const updatedBid = {...this.state.bids[address], bid, bidUsd, bidMax, eth:this.state.usdPerWei};
      this.state.bids[address] = updatedBid;
    }
    if (askMax[0].div(10**this.state.token.decimals).gt(0)) {
      const ask = await contract.getAsk();
      const askUsd = this.weiToUSD(ask).mul(10**this.state.token.decimals).div(10**9);
      this.state.asks[address] = {...this.state.asks[address], ask, askUsd, askMax, eth:this.state.usdPerWei};
    }
  }
  
  async _killContract(contract) {
    try {
      this._dismissTransactionError();
  
      const tx = await contract.kill();
      this.setState({ txBeingSent: tx.hash });
  
      const receipt = await tx.wait();
      console.log(receipt);
      
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
  
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }
  
  async _buyTOKEN(contract, amountETH) {
    try {
      this._dismissTransactionError();
  
      amountETH = ethers.utils.parseEther(amountETH);
      const tx = await contract.buyToken({value:amountETH});
  
      this.setState({ txBeingSent: tx.hash });
  
      const receipt = await tx.wait();
      console.log(receipt);
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalances();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
  
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }
  
  async _sellTOKEN(contract, amountTOKEN) {
    try {
      this._dismissTransactionError();
  
      amountTOKEN = ethers.utils.parseUnits(amountTOKEN, 9);
  
      const allowance = await this._token.allowance(this.state.selectedAddress, contract.address);
      if (amountTOKEN.gt(allowance)) {
        this.setState({ txApprovalNext:true });
        await this._approveToken(contract);
      }
  
      const tx = await contract.sellToken(amountTOKEN);
  
      this.setState({ txBeingSent: tx.hash, txApprovalNext:false });
  
      const receipt = await tx.wait();
      console.log(receipt);
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalances();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
  
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }
  
  
  async _createVendor(bid, ask, bidSize, askSize) {
    try {
      this._dismissTransactionError();
      const token = this.state.token;
      const decimals = token.decimals;
  
      bid = parseFloat(bid).toFixed(18-decimals);
      ask = parseFloat(ask).toFixed(18-decimals);
  
      const bidUSDPerToken = ethers.utils.parseUnits(bid, 18 - decimals);
      const bidWeiPerToken = this.usdToWei(bidUSDPerToken.mul(10**9));
  
  
      const tokenBidSize = ethers.utils.parseUnits(bidSize, decimals);
      const bidSizeETH = bidWeiPerToken.mul(tokenBidSize).div(10**9); 
      console.log(ethers.utils.formatEther(bidSizeETH));
      console.log(bidWeiPerToken.toString());
  
      const askUSDPerToken = ethers.utils.parseUnits(ask, 18 - decimals);
      const askWeiPerToken = this.usdToWei(askUSDPerToken.mul(10**9));
      console.log(askWeiPerToken.toString());
      const tokenAskSize = ethers.utils.parseUnits(askSize, decimals);
  
      const tx = await this._factory.createVendor(bidWeiPerToken, askWeiPerToken, token.address,  {value:bidSizeETH});
      this.setState({ txBeingSent: tx.hash, txApprovalNext: !tokenAskSize.isZero()});
  
      const receipt = await tx.wait();
      console.log(receipt);
  
      const createEvent = receipt.events.find(event => event.event === 'VendorCreated');
      const vendorAddress = createEvent.args.vendor;    
      if (tokenAskSize.gt(0)) {
        const tx2 = await this._token.approve(vendorAddress, tokenAskSize);
        this.setState({ txBeingSent: tx2.hash, txApprovalNext: false});
      }
  
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalances();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
  
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined });
    }
  }
  
  async _approveToken(contract) {
    try {
  
      this._dismissTransactionError();
      const amount = ethers.constants.MaxUint256;
      const tx = await this._token.approve(contract.address, amount);
      this.setState({ txBeingSent: tx.hash});
  
      const receipt = await tx.wait();
      if (receipt.status === 0) {
        throw new Error("Transaction failed");
      }
      await this._updateBalances();
    } catch (error) {
      if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
        return;
      }
  
      console.error(error);
      this.setState({ transactionError: error });
    } finally {
      this.setState({ txBeingSent: undefined, txApprovalNext: false });
    }
  }
  
  
  _startPollingData() {
    this._pollDataInterval = setInterval(() => this._updateBalances(), 1000);
    this._updateBalances();
  }
  
  _stopPollingData() {
    clearInterval(this._pollDataInterval);
    this._pollDataInterval = undefined;
  }
  
  async _updateBalances() {
    fetch("https://api.coinbase.com/v2/exchange-rates?currency=ETH")
    .then(async (response) => {
      const res = await response.json();
      const ethusd = res.data.rates.USD;
      const usdPerWei = ethers.BigNumber.from(ethusd.split(".")[0]);
      this.setState({usdPerWei});
    });
  
    const tokenBalance = await this._token.balanceOf(this.state.selectedAddress);
    const ethBalance = await this._provider.getBalance(this.state.selectedAddress);
  
    this.setState({
      tokenBalance,
      ethBalance,
    });
  
    Object.keys(this.state.bids).forEach(async (address) => {
      this._updateVendor(address);
    });
  
  }
  
  // This method just clears part of the state.
  _dismissTransactionError() {
    this.setState({ transactionError: undefined });
  }
  
  // This method just clears part of the state.
  _dismissNetworkError() {
    this.setState({ networkError: undefined });
  }
  
  // This is an utility method that turns an RPC error into a human readable
  // message.
  _getRpcErrorMessage(error) {
    if (error.data) {
      return error.data.message;
    }
    return error.message;
  }
  
  // This method resets the state
  _resetState() {
    this.setState(this.initialState);
  }
  
  }