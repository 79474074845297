import React from "react";
import "./CarouselIndicators.css";

function CarouselIndicators(props) {
  const { tab, length, pageSize, switchTab } = props;
  const green = "ellipse-3";
  const gray = "ellipse";
  const tabPosition = tab+1;

  return (
    <div className="carousel-indicators-3">
      { (length > pageSize) &&
      <>
        <button className="arrow-chevron-1"
                style={{backgroundImage:"url('/arrow-chevron-3.svg')"}}
                onClick={() => {if (tab > 0) {switchTab(tab-1)}}} />
        <div className="dots-3">
          <div className={tabPosition % 3 == 1 ? green : gray}></div>
          <div className={tabPosition % 3 == 2 ? green : gray}></div>
          <div className={tabPosition % 3 == 0 ? green : gray}></div>
        </div>
        <button className="arrow-chevron-2"
                style={{backgroundImage:"url('/arrow-chevron-4.svg')"}}
                onClick={() => {if(length > (pageSize * tabPosition)) {switchTab(tab+1)}}} />
      </>
      }
    </div>
  );
}

export default CarouselIndicators;
