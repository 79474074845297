import React from "react";
import { ethers } from "ethers";
import { RowTradeD } from "./stubs/RowTradeD";
import RowOrderD from "./stubs/RowOrderD";

export class Ask extends React.Component {

  render() {
    let {address, pp, owner, ask, askUsd, eth, askMax, max_amountETH, token} = this.props;

    const sizeToken = ethers.utils.commify(Math.trunc(ethers.utils.formatUnits(askMax.amountToken, token.decimals))); 
    const askPrice = ethers.utils.formatUnits(askUsd, 18).substring(0,11);

    return (
      <React.Fragment>
        {this.props.tradeAction ?
        <RowTradeD
          wants={{'imgName': pp ? 'moneyfinn.png' : 'logo-eth.png',
                  'tokenName': 'ETH'}}
          quantity={{'imgName':token.logo,
                    'quantity': sizeToken}}
          pricePerToken={askPrice}
          onClick={this.props.tradeAction}
        />
        :
        <RowOrderD
          orderType="SELL"
          quantity={sizeToken}
          symbol={token.symbol}
          pricePerToken={askPrice}
          _killContract={() =>this.props._killContract(ask.contract)}
        />
        }
      </React.Fragment>
    );
  }
}
