import React from "react";
import "./RowOrderD.css";

function RowOrderD(props) {
  const { orderType, quantity, symbol, pricePerToken, _killContract } = props;
  const buySell = (orderType === "BUY") ? "buyOrder roboto-bold-chateau-green-12px" : "sellOrder";

  return (
    <React.Fragment>
    <div className="row-order-d-1" style={{width:"100%", display:"inline-grid"}}>
      <div className={buySell + " valign-text-middle"}>{orderType}</div>
        <div className="token-lockup-9-1">
          <div className="x8800000-1 valign-text-middle roboto-normal-mine-shaft-16px">{quantity}</div>
        </div>
        <div className="hoge-6 valign-text-middle roboto-normal-mine-shaft-12px">{symbol}</div>
      <div className="pad-if-mobile">
        <div className="show-at-symbol">@</div>
        <div className="x0000093600 valign-text-middle roboto-normal-mine-shaft-16px">
          ${pricePerToken}
        </div>
      </div>
      <button className="icon-cancel-x" style={{backgroundImage:"url('/icon-cancel-x@2x.svg')"}} onClick={_killContract} />
    </div>
    <img className="line-7" src="line-1-1.svg" />
    </React.Fragment>
  );
}

export default RowOrderD;
