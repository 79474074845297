import React from "react";
import "./TokenLockup32.css";

function TokenLockup32(props) {
  const { logoHoge, hoge, className } = props;

  return (
    <div className={`token-lockup-3 ${className || ""}`}>
      <img className="logo" src={logoHoge} />
      <div className={"hoge-4 valign-text-middle roboto-bold-white-" + (hoge.length < 6 ? "14px" : "10px")}>{hoge}</div>
    </div>
  );
}

export default TokenLockup32;
