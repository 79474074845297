import React from "react";
import "./Tabs.css";

function MobileTabs(props) {
  const {hiddenMobileTab, hideMobileTab} = {...props}
  const whiteText = "roboto-bold-white-14px";
  const greenText = "roboto-bold-chateau-green-14px";

  return (
    <div className="mobile-tabs">
      <div className="flex-row">
        <button className={"otc " + (hiddenMobileTab === 'OTC' ? whiteText : greenText)}
                onClick={() => hideMobileTab("Limit")}>OTC</button>
        <button className={"otc " + (hiddenMobileTab === 'Limit' ? whiteText : greenText)}
                onClick={() => hideMobileTab("OTC")}>Limit</button>
      </div>
      <div className="rectangle-1" style={{float:(hiddenMobileTab === 'Limit' ? "left" : "right")}}></div>
      <img
        className="line-8"
        src="line-8.svg"
      />
    </div>
  );
}

export default MobileTabs;
