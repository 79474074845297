import React from "react";
import { BigNumber, ethers } from "ethers";
import pairArtifact from "../contracts/pair.json";
import TokenVendorArtifact from "../contracts/TokenVendor.json";
import VendorFactoryArtifact from "../contracts/VendorFactory.json";
import PermanentPumpArtifact from "../contracts/PermanentPump.json";
import erc20ABI from "../contracts/erc20.json";
import pairABI from "../contracts/pair.json";
import { NoWalletDetected } from "./NoWalletDetected";
// import { ConnectWallet } from "./ConnectWallet";
import { Loading } from "./Loading";
import { CreateVendor } from "./CreateVendor";
import { ConfirmTrade } from "./ConfirmTrade";
import { Bid } from "./Bid";
import { Ask } from "./Ask";
import { TransactionErrorMessage } from "./TransactionErrorMessage";
import { WaitingForTransactionMessage } from "./WaitingForTransactionMessage";
import 'bootstrap/dist/css/bootstrap.css';
import { CurrentBreakpoint } from "./UtilityFns";
import ReactModal from 'react-modal';

import { Nav } from './stubs/Nav';
import { MarketPriceDisplay } from './stubs/MarketPriceDisplay';
import ColLabels from "./stubs/ColLabels";
import CarouselIndicators from "./stubs/CarouselIndicators";
import MobileTabs from "./stubs/MobileTabs"
import "../OrderPanel.css";

//import Web3Modal from "web3modal";
import { SafeAppWeb3Modal } from '@gnosis.pm/safe-apps-web3modal';
import WalletConnectProvider from "@walletconnect/web3-provider";

const supportedTokens = require("../contracts/supported-tokens.json");
const factoryAddress = require("../contracts/factory-address.json");

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;
const zero = ethers.BigNumber.from("0");

export class OtcSwap extends React.Component {
  constructor(props) {
    super(props);
    
    this.initialState = {

      tokenData: undefined,
      selectedAddress: undefined,
      initialized: false,
      tradeType:'buy',
      bids:{},
      asks:{},
      orderSelected:false, // bid or ask in state
      tokenBalance: zero,
      ethBalance: zero,
      usdPerWei: ethers.BigNumber.from("1"),
      txBeingSent: undefined,
      txApprovalNext: false,
      transactionError: undefined,
      networkError: undefined,
      token: supportedTokens['hoge'],
      tokenReserves: zero, 
      ethReserves: zero,
      tradeTab: 0,
      orderTab: 0,
      hiddenMobileTab: 'Limit',
      rowCount: 4
  };
    this.state = this.initialState;
    this._provider = new ethers.providers.InfuraProvider("homestead", "3266fa25a9684e69a1c9db908e89cf09");
    const nullSigner = new ethers.VoidSigner("0x0000000000000000000000000000000000000000", this._provider);
    this._factory = new ethers.Contract(
      factoryAddress.address,
      VendorFactoryArtifact.abi,
      nullSigner
    );
    this._loadContracts(nullSigner);
  }

  componentDidMount() {
    ReactModal.setAppElement('body');
  }

  render() {
  const max_amountETH = 3;
  const allOrders = [...Object.values(this.state.asks), ...Object.values(this.state.bids)];
  const availableTrades = allOrders
    // .filter((value, index, self) => self.indexOf(value) == index) //uniqueness - needs tweaking and likely unnecessary
    .filter((trade) => {return (this.state.tradeType === 'buy' ? trade.askMax.amountETH.gt(0) : trade.bidMax.amountETH.gt(0))})
    .sort((trade1, trade2) => {return ((this.state.tradeType === 'buy' ? 
                                         trade1.ask.gte(trade2.ask) : trade1.bid.lte(trade2.bid)) ? 1 : -1)})
    .map((trade) => { return {...trade, 
                              max_amountETH:max_amountETH, 
                              token:this.state.token}});
  const openOrders = [...Object.values(this.state.asks), ...Object.values(this.state.bids)]
    // .filter((value, index, self) => self.indexOf(value) == index) //uniqueness - needs tweaking and likely unnecessary
    .filter((order) => {return order.owner && this.state.selectedAddress && order.owner.toUpperCase() === this.state.selectedAddress.toUpperCase()})
    .filter((order) => {return (order.ask || order.bid).gt(0)})
    .sort((order1, order2) => {return ((order1.ask || order1.bid).gte(order2.ask || order2.bid) ? 1 : -1)})
    .map((order) => { return {...order, token: this.state.token, _killContract: () => this._killContract(order.contract)}});
    console.log(openOrders)
  const txBeingSent = this.state.txBeingSent;
  const txApprovalNext = this.state.txApprovalNext;
  const rowCount = this.state.rowCount;

  return (
    <div className="container-center-horizontal">
      <CurrentBreakpoint 
           rowCount={rowCount}
           setCount={(newRowCount) => { this.setState({rowCount:newRowCount})}}
      />
      <ReactModal 
           isOpen={txBeingSent}
           contentLabel="Pending Transaction"
          //  preventScroll={true}
           style={{
            overlay: {
              position: 'fixed',
              backgroundColor: 'transparent',
            },
            content: {
              position: 'absolute',
              width: "255px",
              height: "auto",
              top: '145px',
              left: 'unset',
              right: '391px',
              bottom: 'unset',
              border: '2px solid var(--chateau-green)',
            }
            }}
      >
        {txBeingSent &&
        <div style={{gap: '20px', fontWeight: '400'}} className="roboto-bold-chateau-green-12px">
          <div style={{display: 'flex', gap: '8px', fontSize: '10px'}}>
            <div>Transaction pending: </div>
              <a style={{display: 'inline-block', width: '77px', overflow: 'hidden', textOverflow: 'ellipsis', textDecorationLine: 'underline'}}
                className="underline"
                target="_blank"
                href={`https://etherscan.io/tx/` + txBeingSent}>{txBeingSent.substring(0,5) + "..." + txBeingSent.substring(txBeingSent.length - 5, txBeingSent.length)  }
              </a>
          </div>
          <div style={{display:"flex"}}>
            <img src={this.state.token.logo} className={"rotation"}/> 
            <img src={this.state.token.logo} className={"rotation"}/>
            <img src={this.state.token.logo} className={"rotation"}/>
          </div>
          {txApprovalNext && <p>Please don't navigate away; 2nd transaction required to complete your order!</p>}
        </div>}

      </ReactModal>
      <div className="otcswap-pro-d-default screen">
        <Nav
          initialized={this.state.initialized}
          selectedAddress={this.state.selectedAddress}
          networkError={this.state.networkError}
          token={this.state.token}
          tokenBalance={this.state.tokenBalance}
          ethBalance={this.state.ethBalance}
          _connectWallet={()=>this._connectWallet()}
          _dismissNetworkError={()=>this._dismissNetworkError()}
          _disconnectWallet={()=> {
                                    this._stopPollingData();
                                    this._resetState();
                                  }}
        />
        <div className="lorem-ipsum-trade-yo">
          The ultimate platform for trustless over-the-counter token trades
        </div>
        <MarketPriceDisplay
          initialized={this.state.initialized}
          ethDisplay={ethers.utils.formatUnits(this.state.usdPerWei, 0)}
          tokenPrice={ethers.utils.formatUnits(this.tokenToUsd(ethers.utils.parseUnits("1", this.state.token.decimals)))}
          token={this.state.token}
          _updateToken={(token) => this._updateToken(token)}
        />
        <MobileTabs 
          hiddenMobileTab={this.state.hiddenMobileTab}
          hideMobileTab={(tab) => this.setState({hiddenMobileTab: tab})}
        />
        <div className="dashboards">
          <div className={"market-orders-panel " + (this.state.hiddenMobileTab === 'Limit' ? "" : "inactiveMobileTab")}>
            <div className="available-trades">
              <h1 className="title roboto-bold-chateau-green-32px">
              Available trades
              </h1>
              <div className="tabs">
                <button id="buy-token-btn"
                        className={this.state.tradeType === 'buy' ? "activeTradeBtn roboto-bold-white-14px" : "inactiveTradeBtn roboto-bold-chateau-green-14px"}
                        onClick={() => this.setState({tradeType: 'buy', orderSelected: false, tradeTab: 0})}>
                  <div className="buy-hoge valign-text-middle">
                    {"Buy " + this.state.token.symbol}
                  </div>
                </button>
                <button id="sell-token-btn"
                        className={this.state.tradeType === 'sell' ? "activeTradeBtn roboto-bold-white-14px" : "inactiveTradeBtn roboto-bold-chateau-green-14px"}
                        onClick={() => this.setState({tradeType: 'sell', orderSelected: false, tradeTab: 0})}>
                  <div className="sell-hoge valign-text-middle">
                  {"Sell " + this.state.token.symbol}
                  </div>
                </button>
              </div>
              <div className="buy-module">
                <div className="table">
                  <div className="col-labels">
                    <img src="line-1-1.svg" /> 
                    <div className="labels roboto-bold-mine-shaft-12px">
                      <div className="someone-wants valign-text-middle">
                        Someone
                        <br/>
                        wants
                      </div>
                      {this.state.tradeType === 'buy' ? 
                        <React.Fragment>
                          <div className="has valign-text-middle">
                            Has
                          </div>
                          <div className="surname valign-text-middle">
                            Price per token
                            <img className="arrow-triangle" src="arrow-triangle.svg" />
                          </div>
                        </React.Fragment> :

                        <React.Fragment>
                          <div className="has valign-text-middle">
                            Token Qty
                          </div>
                          <div className="surname valign-text-middle">
                            Price per token
                            <img className="arrow-triangle" src="arrow-triangle.svg" style={{transform:"rotate(-180deg)"}}/>
                          </div>
                        </React.Fragment>
                      }

                    </div>
                    <img src="line-1-1.svg" />
                  </div>
                  {/* asks / bids */}
                  {availableTrades.slice(this.state.tradeTab * rowCount, this.state.tradeTab * rowCount + rowCount)
                                  .map((trade) => this.state.tradeType === 'buy' ? 
                                    <Ask {...trade} tradeAction= {(ref) => {trade.ref = ref; this.setState({orderSelected: trade});}}/> : 
                                    <Bid {...trade} tradeAction= {(ref) => {trade.ref = ref; this.setState({orderSelected: trade});}}/>)}
                </div>
                <CarouselIndicators 
                  tab={this.state.tradeTab}
                  length={availableTrades.length}
                  pageSize={rowCount}
                  switchTab={(tab) => this.setState({tradeTab: tab, orderSelected: false})}
                />
              </div>
              {this.state.orderSelected?.ref?.current?.offsetTop && 
              <img src="arrow-triangle.svg"
                   style ={{position:"absolute", 
                            left:"713px", 
                            top:(536 + this.state.orderSelected.ref.current.offsetTop + "px"), 
                            transform:"rotate(-90deg)"}}/>
              }
            </div>
            <ConfirmTrade 
              token={this.state.token}
              tokenBalance={this.state.tokenBalance}
              ethBalance={this.state.ethBalance}
              tradeType={this.state.tradeType}
              orderSelected={this.state.orderSelected}
              initialized={this.state.initialized}
            />
          </div>
          <div className={"limit-orders-panel " + (this.state.hiddenMobileTab === 'OTC' ? "" : "inactiveMobileTab")}>
            <div className="your-open-orders">
              <div className="x-order roboto-bold-chateau-green-32px">Your open orders</div>
              <div className="orders-module">
                <div className="table-2" style={{marginBottom:"24px"}}>
                  <ColLabels />
                  {openOrders.slice(this.state.orderTab * rowCount, this.state.orderTab * rowCount + rowCount)
                             .map((order) => { return <>
                                                        {order.ask && <Ask {...order}/>}
                                                        {order.bid && <Bid {...order}/>}
                                                      </>})}
                </div>
                <CarouselIndicators 
                  tab={this.state.orderTab}
                  length={openOrders.reduce((total, order)=>{return total + order.hasOwnProperty('ask') + order.hasOwnProperty('bid')}, 0)}
                  pageSize={rowCount}
                  switchTab={(tab) => this.setState({orderTab: tab})}
                />
              </div>
            </div>
            <CreateVendor 
              initialized={this.state.initialized}
              createVendor={(...args) => this._createVendor(...args)}
              ethBalance={this.state.ethBalance}
              tokenBalance={this.state.tokenBalance}
              token={this.state.token}
              usdPerWei={this.state.usdPerWei}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

componentWillUnmount() {
  this._stopPollingData();
}

async _connectWallet() {
  this._initializeEthers();
}

async _initializeEthers() {
  const web3Modal = new SafeAppWeb3Modal({
    network: "mainnet", // optional
    cacheProvider: true, // optional
    providerOptions: {  
      walletconnect: {
        package: WalletConnectProvider, // required
        options: {
          infuraId: "3266fa25a9684e69a1c9db908e89cf09" // required
        }
    }} // required
  });
  const instance = await web3Modal.requestProvider();
  this._provider = new ethers.providers.Web3Provider(instance);

  const signer = this._provider.getSigner();
  const address = await signer.getAddress();
  this.setState({
    selectedAddress: address,
    initialized: true
  });

  this._factory = new ethers.Contract(
    factoryAddress.address,
    VendorFactoryArtifact.abi,
    signer
  );

  instance.on("accountsChanged", ([newAddress]) => {
    this._stopPollingData();
    if (newAddress === undefined) {
      return this._resetState();
    }
    this.setState({
      selectedAddress: newAddress,
      tokenBalance: zero,
      ethBalance: zero,
    });
    const new_signer = this._provider.getSigner();
    this._factory = new ethers.Contract(
      factoryAddress.address,
      VendorFactoryArtifact.abi,
      new_signer
    );
    this._loadContracts(new_signer);
    this._startPollingData();
  });

  this._loadContracts(signer);
}

async _loadContracts(signer) { 
  await this._updateToken();
  //this._addVendor(PermanentPumpArtifact.address, signer, true);
  let eventFilter = this._factory.filters.VendorCreated;
  let events = await this._factory.queryFilter(eventFilter);
  events.forEach(x=> {
    //if (x.args.token.toUpperCase() !== this.state.token.address.toUpperCase()) return;
    this._addVendor(x.args.vendor, signer)
  });

  //this._factory.provider.resetEventsBlock(15360110); //(14450466);12160486
  //console.log("reset factory")
  //this._factory.on("VendorCreated", (token, vendor) => {
  //  console.log(token, vendor)
  //  this._addVendor(vendor, signer);
  //});
  this._startPollingData();
}

// call when initializing or switching token
async _updateToken(newToken = false) {
  if(newToken) {
    this.setState({token: supportedTokens[newToken]}, () => {
      this._updateToken();
    });
  } else {
    let signer = this.state.initialized ? this._provider.getSigner(0) : new ethers.VoidSigner("0x0000000000000000000000000000000000000000", this._provider);
    this._token = new ethers.Contract(
      this.state.token.address,
      erc20ABI,
      signer
    );
    let symbol = await this._token.symbol();
    let decimals = await this._token.decimals();
    this._pair = new ethers.Contract(
      this.state.token.pair,
      pairArtifact,
      signer
    );
    this.token0 = await this._pair.token0();
    this.setState({token: {...this.state.token, symbol, decimals}});
  }
}

async _addVendor(address, signer, pp = false) {
  let abi = pp ? PermanentPumpArtifact.abi : TokenVendorArtifact.abi;
  let contract = new ethers.Contract(
                  address,
                  abi,
                  signer
                );
  if (pp) {
    const inBusiness = await contract.openForBusiness();
    if (!inBusiness) {
      console.log("NOT IN BIZ")
      return;
    }
  }
  const owner = await contract.owner();
  const empty_max = {amountETH: zero, amountToken:zero}
  const new_bid = {contract, pp:pp, address, owner, bid:zero, bidUsd:zero, bidMax:empty_max, askMax:empty_max, eth:this.state.usdPerWei, 
                    callbacks: {sell: (tokenAmount) => {this._sellTOKEN(contract, tokenAmount)}}};
  this.state.bids[address] = new_bid;
  const new_ask = {contract, pp:pp, address, owner, ask:zero, askUsd:zero, bidMax:empty_max, askMax:empty_max, eth:this.state.usdPerWei,
                    callbacks: {buy: (ethAmount) => {this._buyTOKEN(contract, ethAmount)}}};
  this.state.asks[address] = new_ask;
  this.forceUpdate();
  this._updateVendor(address);
}

async _updateVendor(address) {
  let order = this.state.bids[address] || this.state.asks[address];
  const contract = order.contract;
  let bidMax = await contract.bidSize();
  let askMax = await contract.askSize();
  if (order.pp) {
    bidMax = {amountToken: bidMax.amountHOGE, amountETH: bidMax.amountETH};
    askMax = {amountToken: askMax.amountHOGE, amountETH: askMax.amountETH};
  }
  if (bidMax.amountETH.gt(0)) {
    const bid = await contract.getBid();
    const bidUsd = this.weiToUSD(bid).mul(10**this.state.token.decimals).div(10**9);
    const updatedBid = {...order, bid, bidUsd, bidMax, eth:this.state.usdPerWei};
    this.state.bids[address] = updatedBid;
  } else {
    delete this.state.bids[address]
  }
  if (askMax.amountETH.gt(0)) {
    const ask = await contract.getAsk();
    const askUsd = this.weiToUSD(ask).mul(10**this.state.token.decimals).div(10**9);
    let updatedAsk = {...this.state.asks[address], ask, askUsd, askMax, eth:this.state.usdPerWei};
    this.state.asks[address] = updatedAsk;
  } else {
    delete this.state.asks[address];
  }
  this.forceUpdate();
}

async _killContract(contract) {
  try {
    this._dismissTransactionError();

    const tx = await contract.kill();
    this.setState({ txBeingSent: tx.hash });

    const receipt = await tx.wait();
    console.log(receipt);
    
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }

    console.error(error);
    this.setState({ transactionError: error });
  } finally {
    this.setState({ txBeingSent: undefined });
  }
}

async _buyTOKEN(contract, amountETH) {
  try {
    this._dismissTransactionError();

    amountETH = ethers.utils.parseEther(amountETH);
    const tx = await contract.buyToken({value:amountETH});

    this.setState({ txBeingSent: tx.hash });

    const receipt = await tx.wait();
    console.log(receipt);
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
    await this._updateBalances();
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }

    console.error(error);
    this.setState({ transactionError: error });
  } finally {
    this.setState({ txBeingSent: undefined });
  }
}

async _sellTOKEN(contract, amountTOKEN) {
  try {
    this._dismissTransactionError();

    amountTOKEN = ethers.utils.parseUnits(amountTOKEN, 9);

    const allowance = await this._token.allowance(this.state.selectedAddress, contract.address);
    if (amountTOKEN.gt(allowance)) {
      this.setState({ txApprovalNext:true });
      await this._approveToken(contract);
    }

    const tx = await contract.sellToken(amountTOKEN);

    this.setState({ txBeingSent: tx.hash, txApprovalNext:false });

    const receipt = await tx.wait();
    console.log(receipt);
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
    await this._updateBalances();
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }

    console.error(error);
    this.setState({ transactionError: error });
  } finally {
    this.setState({ txBeingSent: undefined });
  }
}

tokenToWei(tokenAmount) {
  if (tokenAmount.isZero() || this.state.tokenReserves.isZero()) {return ethers.BigNumber.from("0");}
  return tokenAmount.mul(this.state.ethReserves).div(this.state.tokenReserves);
}

weiToToken(weiAmount) {
  if (weiAmount.isZero() || this.state.ethReserves.isZero()) {return ethers.BigNumber.from("0");}
  return weiAmount.mul(this.state.tokenReserves).div(this.state.ethReserves)
}

weiToUSD(weiAmount) {
  return weiAmount.mul(this.state.usdPerWei);
}

usdToWei(usdAmount) {
  return usdAmount.div(this.state.usdPerWei);
}

tokenToUsd(tokenAmount) {
  return this.weiToUSD(this.tokenToWei(tokenAmount));
}

usdToToken(usdAmount) {
  return this.weiToToken(this.usdToWei(usdAmount));
}

async _createVendor(bid, ask, bidSize, askSize) {
  try {
    this._dismissTransactionError();
    const token = this.state.token;
    const decimals = token.decimals;

    bid = parseFloat(bid).toFixed(18-decimals);
    ask = parseFloat(ask).toFixed(18-decimals);

    const bidUSDPerToken = ethers.utils.parseUnits(bid, 18 - decimals);
    const bidWeiPerToken = this.usdToWei(bidUSDPerToken.mul(10**9));

    const tokenBidSize = ethers.utils.parseUnits(bidSize, decimals);
    const bidSizeETH = bidWeiPerToken.mul(tokenBidSize).div(10**9); 
    const askUSDPerToken = ethers.utils.parseUnits(ask, 18 - decimals);
    const askWeiPerToken = this.usdToWei(askUSDPerToken.mul(10**9));
    const tokenAskSize = ethers.utils.parseUnits(askSize, decimals);

    const tx = await this._factory.createVendor(bidWeiPerToken, askWeiPerToken, token.address,  {value:bidSizeETH});
    this.setState({ txBeingSent: tx.hash, txApprovalNext: !tokenAskSize.isZero()});

    const receipt = await tx.wait();
    console.log(receipt);

    const createEvent = receipt.events.find(event => event.event === 'VendorCreated');
    const vendorAddress = createEvent.args.vendor;    
    if (tokenAskSize.gt(0)) {
      const tx2 = await this._token.approve(vendorAddress, tokenAskSize);
      this.setState({ txBeingSent: tx2.hash, txApprovalNext: false});
    }

    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
    await this._updateBalances();
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }

    console.error(error);
    this.setState({ transactionError: error });
  } finally {
    this.setState({ txBeingSent: undefined });
  }
}

async _approveToken(contract) {
  try {

    this._dismissTransactionError();
    const amount = ethers.constants.MaxUint256;
    const tx = await this._token.approve(contract.address, amount);
    this.setState({ txBeingSent: tx.hash});

    const receipt = await tx.wait();
    if (receipt.status === 0) {
      throw new Error("Transaction failed");
    }
    await this._updateBalances();
  } catch (error) {
    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
      return;
    }

    console.error(error);
    this.setState({ transactionError: error });
  } finally {
    this.setState({ txBeingSent: undefined, txApprovalNext: false });
  }
}


_startPollingData() {
  this._pollDataInterval = setInterval(() => this._updateBalances(), 10000);
  this._updateBalances();
}

_stopPollingData() {
  clearInterval(this._pollDataInterval);
  this._pollDataInterval = undefined;
}

async _updateBalances() {
  fetch("https://api.coinbase.com/v2/exchange-rates?currency=ETH")
  .then(async (response) => {
    const res = await response.json();
    const ethusd = res.data.rates.USD;
    const usdPerWei = ethers.BigNumber.from(ethusd.split(".")[0]);
    this.setState({usdPerWei});
  });

  if (this.state.initialized) {
    const tokenBalance = await this._token.balanceOf(this.state.selectedAddress);
    const ethBalance = await this._provider.getBalance(this.state.selectedAddress);

    this.setState({
      tokenBalance,
      ethBalance,
    });
  }

  const [token0reserves, token1reserves] = await this._pair.getReserves();
  const [tokenReserves, ethReserves] = this.token0 == "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" ? [token1reserves, token0reserves] : [token0reserves, token1reserves];
  this.setState({tokenReserves, ethReserves});
  
  Object.keys(this.state.bids).forEach(async (address) => {
    this._updateVendor(address);
  });

}

// This method just clears part of the state.
_dismissTransactionError() {
  this.setState({ transactionError: undefined });
}

// This method just clears part of the state.
_dismissNetworkError() {
  this.setState({ networkError: undefined });
}

// This is an utility method that turns an RPC error into a human readable
// message.
_getRpcErrorMessage(error) {
  if (error.data) {
    return error.data.message;
  }
  return error.message;
}

// This method resets the state
_resetState() {
  this.setState(this.initialState);
}

}