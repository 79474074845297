import React from "react";

const supportedTokens = require("../../contracts/supported-tokens.json");

export function MarketPriceDisplay(props) {
  const { initialized, ethDisplay, tokenPrice, token, _updateToken } = props;
  const selectRef = React.useRef();

  return (
    <div className="market-price-display roboto-medium-white-12px">
      <div className="current-market-prices valign-text-middle">
      Current market prices:
      </div>
      <div className="fields">
        <div className="field-2">
          <div className="place-1 valign-text-middle">
            ETH
          </div>
          <div className="price-1 valign-text-middle roboto-medium-white-12px">
            ${ethDisplay}
          </div>
        </div>
        <div className="field-3">
          <div className="frame-12">
            <div className="hoge-2 valign-text-middle">{token.symbol}</div>
            <div className="price valign-text-middle roboto-medium-white-12px">
              ${tokenPrice ? parseFloat(tokenPrice).toFixed(9) : ""}
            </div>
          </div>
          <img className="line-5" src="line-5-1.svg" />
          <select className="hoge-2 valign-text-middle"
                  disabled={!initialized}
                  style={{backgroundColor: "transparent", 
                          backgroundImage: "url(/arrow-chevron-5.svg)", 
                          appearance: "none", 
                          backgroundRepeat: "no-repeat", 
                          backgroundPosition: "right 0.5em top, 0", 
                          width: "30px", 
                          color: "transparent"}}
                  ref={selectRef} onChange={(e) => {_updateToken(e.target.value)}}>
            {Object.entries(supportedTokens).map((token) => {
              const [key, value] = [token[0], token[1].symbol];
              return <option value={key} style={{color:"black"}}>{value}</option>
            })}
            </select>
        </div>
      </div>
    </div>
  );
}