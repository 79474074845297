import React from "react";
import { SellModule } from "./stubs/SellModule";
import LimitOrderBtn from "./stubs/LimitOrderBtn";
import { ethers } from "ethers";
import { _tokenDisplay } from "./UtilityFns";

export class CreateVendor extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      orderType:'buy',
    };
    this.state = this.initialState;
  }

  processOrder(price, size) {
    const [bid, ask, bidSize, askSize] = this.state.orderType === 'buy' ? [price, '0', size, '0'] : ['0', price, '0', size];
    this.props.createVendor(bid, ask, bidSize, askSize);
  }

  render() {
    let {initialized, createVendor, ethBalance, tokenBalance, token, usdPerWei} = this.props;
    ethBalance = ethers.utils.formatEther(ethBalance);
    const etherPrice = ethers.utils.parseEther("1").mul(usdPerWei);
    return (
      <div className="place-limit-order">
        <div className="x-order roboto-bold-white-32px">Place limit order</div>
        <div className="orderBody">
          <div className="wallet-info">
            <div className="frame-18">
              <div className="text-5 valign-text-middle roboto-bold-mine-shaft-12px">{initialized && tokenBalance ? _tokenDisplay(tokenBalance) : "–"}</div>
              <div className="hoge valign-text-middle roboto-bold-white-12px">{this.props.token.symbol}</div>
            </div>
            <div className="frame-19">
              <div className="text-6 valign-text-middle roboto-bold-mine-shaft-12px">{initialized && ethBalance ? parseFloat(ethBalance).toFixed(2) : "–"}</div>
              <div className="place valign-text-middle roboto-bold-white-12px">ETH</div>
            </div>
          </div>
          <div className="tabs-1">
            <LimitOrderBtn status={this.state.orderType === 'buy'} 
                           style={{borderRadius:"6px 0px 0px 6px"}}
                           onClick={() => this.setState({orderType: 'buy'})}
                           disabled={!initialized}>
                             Buy
            </LimitOrderBtn>
            <LimitOrderBtn status={this.state.orderType === 'sell'}
                           style={{borderRadius:"0px 6px 6px 0px"}}
                           onClick={() => this.setState({orderType: 'sell'})}
                           disabled={!initialized}>
                             Sell
            </LimitOrderBtn>
          </div>
          <SellModule
            disabled={!initialized}
            orderType={this.state.orderType}
            processOrder={(...args) => this.processOrder(...args)}
            etherPrice={etherPrice}
            tokenBalance={this.props.tokenBalance}
            ethBalance={this.props.ethBalance}
            token={token}
          />
        </div>
      </div>
    );
  }
}
