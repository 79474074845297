import React from "react";
import TokenLockup32 from "../TokenLockup32";
import "./SellModule.css";
import { ethers } from "ethers";

export class SellModule extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      price: "0.0",
      size: "0"
    };
    this.state = this.initialState;
  }

  render() {
    const price = parseFloat(this.state.price);
    const size = parseFloat(this.state.size);
    const etherPrice = parseFloat(ethers.utils.formatEther(this.props.etherPrice));
    const earnings = (this.state.price && this.state.size) ?
                     price / etherPrice * size :
                     0;

    return (
      <div className={`sell-module-2`}>
        <div className="field-labeled-default-2">
          <div className="limit-price valign-text-middle roboto-bold-white-12px">Limit price</div>
          <div className="field-7">
            <span className="roboto-bold-white-16px">$</span>
            <input 
              className="price-6 valign-text-middle roboto-bold-white-16px" 
              style={{background:"transparent"}} type="number" step="any" name="price" required
              value={this.state.price}
              disabled={this.props.disabled}
              onChange={
                (event) => {
                    let val = event.target.value;
                    // if (val === "" || val === "0") { val = "0.0"};
                    this.setState({price: val});
                  }
              }
            />
            <div className="surname-3 valign-text-middle roboto-medium-white-10px">PRICE PER TOKEN</div>
          </div>
        </div>
        <div className="field-labeled-default-3">
          <div className="quantity valign-text-middle roboto-bold-white-12px">Quantity</div>
          <div className="field-7">
            <input 
              className="text-7 valign-text-middle roboto-bold-white-16px" 
              style={{background:"transparent", maxWidth:"175px"}} type="number" step="any" name="size" required
              value={this.state.size}
              disabled={this.props.disabled}
              onChange={
                (event) => {
                    let val = event.target.value;
                    this.setState({size: val});
                  }
              }
            />
            <div className="token-info-3">
              <button className="name-2 valign-text-middle roboto-medium-white-10px"
                      disabled={this.props.disabled}
                      onClick={() => {
                        let tokenSize;
                        if (this.props.orderType === 'buy') {
                          const gasMargin = ethers.utils.parseEther(".01");
                          if (price == 0 || this.props.ethBalance.lt(gasMargin)) return;
                          const toSpend = this.props.ethBalance.sub(gasMargin);
                          const tokenPerEth = parseInt(etherPrice / price);
                          tokenSize = toSpend.mul(tokenPerEth).div(10**9);
                        } else {
                          tokenSize = this.props.tokenBalance;
                        }
                        this.setState({size: ethers.utils.formatUnits(tokenSize, this.props.token.decimals)})
                      }}>MAX</button>

              <TokenLockup32
                logoHoge={this.props.token.logo}
                hoge={this.props.token.symbol}
                className={"token-lockup-5"}
              />
            </div>
          </div>
        </div>
        <div className="totals">
          <div className="total-earnings roboto-medium-white-14px">Total {this.props.orderType === 'buy' ? "cost" : "earnings"}:</div>
          <div className="x04-eth roboto-bold-white-14px">{earnings.toFixed(6)} ETH</div>
        </div>
        <button className="button-11" disabled={this.props.disabled}>
          <div className="place-sell-order valign-text-middle roboto-bold-chateau-green-14px"
               onClick={()=>{
                 if(!this.props.disabled){ this.props.processOrder(this.state.price, this.state.size)}}
               }>
              Place {this.props.orderType} order
          </div>
        </button>
      </div>
    );
  }
}
