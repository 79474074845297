import React from "react";
import { ethers } from "ethers";
import { RowTradeD } from "./stubs/RowTradeD";
import RowOrderD from "./stubs/RowOrderD";

export class Bid extends React.Component {

  render() {
    let {address, pp, owner, bid, bidUsd, eth, bidMax, max_amountETH, token} = this.props;

    const sizeToken = ethers.utils.commify(Math.trunc(ethers.utils.formatUnits(bidMax.amountToken, token.decimals))); 
    const bidPrice = ethers.utils.formatUnits(bidUsd, 18).substring(0,11);
    
    return (
      <React.Fragment>
        {this.props.tradeAction ?
        <RowTradeD
          wants={{'imgName': pp ? 'lossfinn.png' : token.logo,
                  'tokenName': token.symbol}}
          quantity={{'quantity':sizeToken}}
          pricePerToken={bidPrice}
          onClick={this.props.tradeAction}
        />
        :
        <RowOrderD
          orderType="BUY"
          quantity={sizeToken}
          symbol={token.symbol}
          pricePerToken={bidPrice}
          _killContract={() =>this.props._killContract(bid.contract)}
        />
        }
      </React.Fragment>
    );
  }
}

